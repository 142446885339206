import _ from 'lodash';
import { useRoutes, useSearchParams } from 'react-router-dom';
import { useAuth } from '@/hooks';
import { mainRoutes, generalRoutes } from '@/routes';
import Loading from '@/components/Loading';
import { getAccessToken } from '@/utils/token';
import { SSORedirectUtil } from '@/utils/auth/ssoRedirectUtil';

export const AppRoutes = () => {
  const { currentUser, isFetching, isNewcomer } = useAuth();
  const isLogin = !_.isEmpty(currentUser);
  const accessToken = getAccessToken();
  const main = useRoutes(mainRoutes(currentUser, isNewcomer));
  const general = useRoutes(generalRoutes(currentUser, isNewcomer));
  const [searchParams] = useSearchParams();
  const st = searchParams.get('st') || '';

  if (isFetching) return <Loading />; // fetching : render null
  if (!isLogin) {
    // 尚未登入或還沒取得 user
    if (_.isEmpty(accessToken)) {
      if (_.isEmpty(st)) {
        return SSORedirectUtil.redirectCasLoginPath(true);
      }
      return <Loading />; // 有 st 表示正在向 cas 請求驗證但 api 尚未返回，沒取到 res 先返回 loading
    }
    return <Loading />; // 有 accessToken 表示有登入但 api 尚未返回，沒取到 user 先返回 loading
  }

  const { is_clinic_account = false } = currentUser;
  if (isNewcomer && !is_clinic_account) return <>{general}</>; // account 已登入且非診所帳號, 但從未使用過 ws

  return <>{main}</>;
};
