import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from '@/utils/token';

const baseUrl = process.env.REACT_APP_CAS_API_URL;

export const baseCasApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: 'include',
  }),
  reducerPath: 'baseCasApiReducer',
  endpoints: () => ({}),
});
