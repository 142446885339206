import _ from 'lodash';
import Cookies from 'js-cookie';
import { sessionDomain } from '@/constants/config';

export const getAccessToken = () => {
  return Cookies.get('at');
};

export const removeAccessToken = () => {
  Cookies.remove('at', { domain: sessionDomain });
};

export const saveAccessToken = (accessToken, exp) => {
  Cookies.set('at', accessToken, { expires: exp, domain: sessionDomain });
};

export const removeAuthTokens = () => {
  removeAccessToken();
  removeWorkspaceId();
};

export function removeWorkspaceId() {
  return Cookies.remove('wsId', { domain: sessionDomain });
}

export function getGeoLocations() {
  const geoLocations = Cookies.get('geoLocations', { domain: sessionDomain });
  if (!_.isEmpty(geoLocations)) {
    try {
      return JSON.parse(geoLocations);
    } catch (e) {
      return {
        locations: '',
        locale: '',
      };
    }
  } else {
    return {
      locations: '',
      locale: '',
    };
  }
}

export function setGeoLocations(locations, locale) {
  const geoLocations = {
    locations,
    locale,
  };
  return Cookies.set('geoLocations', JSON.stringify(geoLocations), { domain: sessionDomain });
}

export const saveWorkspaceId = (wsId, exp = 30) => {
  return Cookies.set('wsId', wsId, { expires: exp, domain: sessionDomain });
};
