import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'homePage/authentication',
  initialState: {
    fetchStatus: {},
    currentUser: null,
    isNewcomer: undefined,
  },
  reducers: {
    appInitFetching: (state, action) => {
      return {
        ...state,
        fetchStatus: { ...state.fetchStatus, ...action.payload },
      };
    },
    updateCurrentUser: (state, action) => {
      return {
        ...state,
        currentUser: !_.isEmpty(action.payload) ? action.payload : {},
      };
    },
    updateComerStatus: (state, action) => {
      const { is_newcomer = true } = action.payload;
      return {
        ...state,
        isNewcomer: is_newcomer,
      };
    },
  },
});

export const { appInitFetching, updateCurrentUser, updateComerStatus } = authSlice.actions;

export default authSlice.reducer;
