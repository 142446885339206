import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createSelector } from '@reduxjs/toolkit';
import { getAccessToken } from '@/utils/token';
import { useStVerifyQuery, useFetchProfileQuery } from '@/services/cas/auth';
import { useGetComerStatusQuery } from '@/services/dashboard';
import { appInitFetching } from '@/container/HomePage/slice/authSlice';

const name = 'appInit';

const authReSelector = createSelector(
  state => state.homePageReducer.authentication,
  ({ currentUser, fetchStatus, isNewcomer }) => ({
    currentUser,
    isFetching: fetchStatus.appInit,
    isNewcomer,
  }),
);

export function useAuth() {
  const { currentUser } = useSelector(authReSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search = '' } = useLocation();
  const [searchParams] = useSearchParams();
  const st = searchParams.get('st') || '';

  const accessTokenVerified = useRef(false);
  const accessToken = getAccessToken();
  const { isLoading: isStVerifying } = useStVerifyQuery({ st, search, navigate }, { skip: _.isEmpty(st) });
  const { isLoading: isFetchingProfile } = useFetchProfileQuery(
    {},
    { skip: !(_.isEmpty(st) && !_.isEmpty(accessToken) && !accessTokenVerified.current) },
  );
  useGetComerStatusQuery(null, { skip: _.isEmpty(accessToken) || _.isEmpty(currentUser) });

  useEffect(() => {
    if (isStVerifying) {
      dispatch(appInitFetching({ [name]: true }));
    }
  }, [dispatch, isStVerifying]);

  useEffect(() => {
    if (isFetchingProfile && !accessTokenVerified.current) {
      accessTokenVerified.current = true;
      dispatch(appInitFetching({ [name]: true }));
    }
  }, [dispatch, isFetchingProfile]);

  return useSelector(authReSelector);
}
