import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken, getGeoLocations } from '@/utils/token';

const baseUrl = process.env.REACT_APP_SELF_API_URL;

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      headers.set('location', `${encodeURIComponent(getGeoLocations()?.locations)}`);
      return headers;
    },
    credentials: 'include',
  }),
  reducerPath: 'baseApi',
  endpoints: () => ({}),
});
