export const apiHeadersType = {
  jsonType: 'application/json',
};
export const selfUrl = process.env.REACT_APP_SELF_URL || '';
export const apiUrl = process.env.REACT_APP_SELF_API_URL || '';
export const casUrl = process.env.REACT_APP_CAS_URL || '';
export const casApiUrl = process.env.REACT_APP_CAS_API_URL || '';
export const casLoginUrl = process.env.REACT_APP_CAS_LOGIN_URL || '';
export const casSwitchAuthUrl = process.env.REACT_APP_CAS_SWITCH_AUTH_URL || '';
export const casLogoutUrl = process.env.REACT_APP_CAS_LOGOUT_URL || '';
export const helpfulLink = 'https://dentall.notion.site/dentall-Help-Desk-4e77b88ef75d42ea923cbaf586c265bc';
export const sessionDomain = process.env.REACT_APP_SESSION_DOMAIN || '';
export const myClinicAccountLink = process.env.REACT_APP_CAS_MYACCOUNT_URL || '';
export const myAccountLink = process.env.REACT_APP_WWW_MYACCOUNT_URL || '';
export const labUrl = process.env.REACT_APP_LAB_URL || '';
export const shopUrl = process.env.REACT_APP_PURCHASE_URL || '';
export const googleGeoKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY || '';
export const lineLink = 'https://line.me/ti/p/%40dentaltw';
export const HIS_AUTO_SYNC_LAB = 'His 自動登入 Lab';
