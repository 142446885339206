import { createBrowserHistory } from 'history';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import homePageReducer from '@/container/HomePage/slice';
import { baseApi } from './services/api';
import { baseCasApi } from './services/cas/casApi';
import workspacePageReducer from '@/container/WorkspacePage/slice';
import memberPageReducer from '@/container/MemberPage/slice';
import historyPageReducer from '@/container/HistoryPage/slice';
import { appInitFetching, updateCurrentUser } from '@/container/HomePage/slice/authSlice';
import { removeAuthTokens, saveAccessToken } from '@/utils/token';
import { authApi } from '@/services/cas/auth';

export const history = createBrowserHistory();

export const rtkQueryErrorHandler = api => next => async action => {
  const { dispatch } = api;
  if (isRejectedWithValue(action)) {
    const payload = action.payload;
    switch (payload.code) {
      case 'ACCESS_TOKEN_IS_EXPIRED':
        try {
          dispatch(appInitFetching({ appInit: true }));
          // Call renewToken API to get a new token
          const renewAccessToken = await dispatch(authApi.endpoints.renewToken.initiate()).unwrap();
          saveAccessToken(renewAccessToken);

          // Retry the original API request with the new token
          const originalRequest = action.meta.arg;
          const endpointName = originalRequest.endpointName;
          const endpointAction = baseCasApi.endpoints[endpointName];
          await dispatch(endpointAction.initiate(originalRequest));
          dispatch(appInitFetching({ appInit: false }));
        } catch (renewError) {
          console.error('Token renewal failed', renewError);
          removeAuthTokens();
          dispatch(updateCurrentUser(undefined));
          dispatch(appInitFetching({ appInit: false }));
        }
        break;
      case 'REFRESH_TOKEN_IS_EXPIRED':
      case 'REFRESH_TOKEN_NOT_FOUND':
        removeAuthTokens();
        dispatch(updateCurrentUser(undefined));
        dispatch(appInitFetching({ appInit: false }));
        break;
      default:
        break;
    }
  }
  return next(action);
};

export default configureStore({
  reducer: {
    [baseCasApi.reducerPath]: baseCasApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    homePageReducer,
    workspacePageReducer,
    memberPageReducer,
    historyPageReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      baseApi.middleware,
      baseCasApi.middleware,
      rtkQueryErrorHandler,
    ),
  devTools: process.env.REACT_APP_ENV === 'dev',
});
